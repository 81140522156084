import React from "react";
import { MuiPickersUtilsProvider } from "@bilgorajskim/material-ui-pickers";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";

function getPlMonth(date) {
  let mon = "";
  if (date.month === 1) {
    mon = "sty";
  } else if (date.month === 2) {
    mon = "lut";
  } else if (date.month === 3) {
    mon = "mar";
  } else if (date.month === 4) {
    mon = "kwi";
  } else if (date.month === 5) {
    mon = "maj";
  } else if (date.month === 6) {
    mon = "cze";
  } else if (date.month === 7) {
    mon = "lip";
  } else if (date.month === 8) {
    mon = "sie";
  } else if (date.month === 9) {
    mon = "wrz";
  } else if (date.month === 10) {
    mon = "paź";
  } else if (date.month === 11) {
    mon = "lis";
  } else if (date.month === 12) {
    mon = "gru";
  }
  return mon;
}

class LocalizedUtils extends LuxonUtils {
  getDateTimePickerHeaderText(date) {
    let mon = getPlMonth(DateTime.fromMillis(date.ts));
    return `${date.day} ${mon}`;
  }
  format(date, formatString) {
    const datetime = DateTime.fromMillis(date.ts);
    if (formatString === "d") {
      return datetime.toFormat(formatString);
    }
    let start = datetime.toFormat("d");
    let mon = getPlMonth(datetime);
    let end = datetime.toFormat("yyyy");
    if (formatString.includes("H")) {
      end += " " + datetime.toFormat("H:mm");
    }
    return `${start} ${mon} ${end}`;
  }
}

const DateTimeContext = ({ children }) => {
  return (
    <MuiPickersUtilsProvider
      utils={LocalizedUtils}
      locale={typeof navigator !== "undefined" ? navigator.language : "pl-PL"}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeContext;
